<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <span class="brand-logo">
        <b-img :src="appLogoImage" alt="logo" class="img_logo"/>
      </span>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
            <h1 class="mb-1">
              Succes
            </h1>
            <p class="mb-2">
              Data has been entered
            </p>
    
            <b-button
              variant="primary"
              class="mb-2 btn-sm-block"
              :to="{path:'/registration'}"
            >
              Back to home
            </b-button>
    
            <!-- image -->
            <b-img
              fluid
              :src="imgUrl"
              alt="Error page"
            />
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  setup() {
      // App Name
      const { appName, appLogoImage } = $themeConfig.app;
      return {
        appName,
        appLogoImage,
      };
    },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  methods: {
      init: function () {},
        Succes: function () {
        window.location.href="https://netciti.co.id/";  
      }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
.mb-2{
  font-size: 15px;
}
.img_logo{
  margin-top: -40px;
  margin-left: -40px;
}
.w-100{
  padding-top: 40px
}
</style>
